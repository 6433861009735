@import "~antd/dist/antd.less";
@import "./theme.less";
@import "src/utils/bootstrap.less";

.icon-medium {
  font-size: 18px;
}

.ant-descriptions-item-label {
  align-items: center !important;
}

.icon-large {
  font-size: 24px;
}

.icon-warning {
  color: @warning-color;
}

.icon-info {
  color: @info-color;
}

.icon-danger {
  color: @error-color;
}

.tabs-container,
.accounts-list-container {
  padding: 50px;
  min-height: calc(~"100vh - " @layout-header-height);

  .content {
    padding: 24px;
    background-color: white;
  }

  & > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px;
    background: #fff;
  }

  //& > .ant-tabs-card > .ant-tabs-nav::before {
  //  display: none;
  //}
  //
  //& > .ant-tabs-card .ant-tabs-tab {
  //  background: transparent;
  //  border-color: transparent;
  //}
  //
  //& > .ant-tabs-card .ant-tabs-tab-active {
  //  background: #fff;
  //  border-color: #fff;
  //}
  //
  & > .ant-tabs-card .ant-tabs-content {
    height: auto;
    margin-top: -16px;
  }
}

.message-count-badge {
  .ant-scroll-number-only:extend(.px-2) {
  }

  .ant-badge-count {
    border-radius: 8px;
  }
}

.ant-table-content {
  overflow: auto;
}
