.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.rtl-table {
  background-color: white;
  flex-grow: 1;
}

.rtl-table-row,
.rtl-table-header {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.rtl-table-header {
  padding: 1rem;
  font-weight: 500;
}

.rtl-table-row-selected {
  background-color: #ececec;
}

.rtl-table-row:hover {
  background-color: #e8e8e8;
}

.rtl-table-row > div {
  width: 130px;
  padding-left: 10px;
}

.rtl-table-row > .big {
  width: 140px;
}

.rtl-table-row > .small {
  width: 110px;
}

@media (max-width: 1920px) {
  .rtl-table-row > div {
    width: 200px;
  }

  .rtl-table-row > .big {
    width: 210px;
  }

  .rtl-table-row > .small {
    width: 180px;
  }
}

@media (max-width: 1440px) {
  .rtl-table-row > div {
    width: 130px;
  }

  .rtl-table-row > .big {
    width: 150px;
  }

  .rtl-table-row > .small {
    width: 110px;
  }
}

.rtl-table-row > .select {
  width: 40px;
}

.rtl-table .rtl-list:focus-visible {
  outline: none;
}
.rtl-table .rtl-list {
  scroll-behavior: smooth;
}

.event-status {
  cursor: pointer;
}
